export const APP_ID_NAME_MAP = {
  1: 'premium',
  2: 'admin',
  14: 'bmwgroup',
  17: 'bmwyuc',
  23: 'mini',
  25: 'vwn',
  26: 'bmwcee',
  27: 'auto_auctions',
  28: 'mp',
  31: 'audi',
  33: 'parts',
  35: 'alphabet',
  37: 'quickbuy',
  54: 'aggregatebid'
}
// info: if you made a change here remember to update `./APP_NAME_ID_MAP.ts`
